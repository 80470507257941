import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { GetBlogPostData } from "components/ApiServices/GetBlogPostsdata";
import { GetServicesData } from "components/ApiServices/GetServicesData";
import { GetTechnologies } from "components/ApiServices/GetTechnologies";
import { GetProjectsData } from "components/ApiServices/GetProjectsData";
import { GatsbyImage } from "gatsby-plugin-image";

const Footer = ({ hideBGCOLOR, theme }) => {
  const recentNewsData = GetBlogPostData();
  const servicesData = GetServicesData();
  const allProjects = GetProjectsData();
  const footerProjects = allProjects.slice(0, 10);
  const technologies = GetTechnologies();
  const flattenedTechnologies = [].concat(...technologies);

  const [year, setYear] = useState(null);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const _resolveTimestamp = (timeStamp) => {
    const date = new Date(timeStamp).toLocaleDateString("en-US");
    return date;
  };

  return (
    <>
      <div className="desktop-links sub-bg footer-add-links">
        <div className="f-link">
          <div className="f-head mb-20">
            <h5>Services</h5>
          </div>

          <div className="link-list d-flex flex-row column-gap-3">
            <ul className="d-flex flex-column">
              {servicesData.map((service, index) => (
                <>
                  {service?.node?.technologiesData &&
                    service?.node?.technologiesData?.nodes
                      .slice(0, 2)
                      .map((technology, index) => (
                        <>
                          <Link
                            to={`/services/${service?.node?.slug}/${technology?.slug}`}
                          >
                            <li key={index}>{technology?.title}</li>
                          </Link>
                        </>
                      ))}
                </>
              ))}
              <li><a href="/one-time-marketing-pack">Digital Marketing</a></li>
            </ul>
          </div>
        </div>

        <div className="f-tech tech">
          <div className="f-head mb-20">
            <h5>Technologies</h5>
          </div>

          <div className="link-list d-flex flex-row column-gap-3">
            <ul className="d-flex flex-column">
              {flattenedTechnologies &&
                flattenedTechnologies?.slice(0, 6).map((technology, index) => (
                  <>
                    <Link to={`/technologies/${technology?.node?.slug}`}>
                      <li key={index}>{technology?.node?.title}</li>
                    </Link>
                  </>
                ))}
            </ul>
          </div>
        </div>

        <div className="f-tech">
          <div className="f-head mb-20">
            <h5>Industries</h5>
          </div>

          <div className="link-list d-flex flex-row column-gap-3">
            <ul className="d-flex flex-column">
              <li>Resturants</li>
              <li>Real Estate</li>
              <li>E-Commerce</li>
              <li>Education</li>
              <li>Saas</li>
              <li>News</li>
            </ul>
          </div>
        </div>

        <div className="f-tech">
          <div className="f-head mb-20">
            <h5>Projects</h5>
          </div>

          <div className="link-list d-flex flex-row column-gap-3">
            <ul className="d-flex flex-column">
              {footerProjects.slice(0, 6).map((Project, index) => (
                <>
                  <Link to={`/work/${Project.node.slug}`}>
                    <li key={index}>{Project.node.projectName}</li>
                  </Link>
                </>
              ))}
            </ul>
          </div>
        </div>

        <div className="f-tech">
          <div className="f-head mb-20">
            <h5>About Us</h5>
          </div>

          <div className="link-list">
            <ul className="d-flex flex-column">
              <Link to="/about">
                <li>About</li>
              </Link>

              <Link to="/contact">
                <li>Contact Us</li>
              </Link>

              <Link to="/blog">
                <li>Blog</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>

      <div className="mobile-links container flex-wrap sub-bg footer-add-links">
        <div className="accordion" id="accordion">
          <div class="card f-tech">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <div
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="f-head">
                    <h5>Services</h5>
                  </div>
                </div>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="link-list d-flex flex-row column-gap-3">
                  <ul className="d-flex flex-column">
                    {servicesData.map((service, index) => (
                      <>
                        {service?.node?.technologiesData &&
                          service?.node?.technologiesData?.nodes
                            .slice(0, 2)
                            .map((technology, index) => (
                              <>
                                <Link
                                  to={`/services/${service?.node?.slug}/${technology?.slug}`}
                                >
                                  <li key={index}>{technology?.title}</li>
                                </Link>
                              </>
                            ))}
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion" id="accordion">
          <div class="card f-tech">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <div
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <div className="f-head">
                    <h5>Technologies</h5>
                  </div>
                </div>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="link-list d-flex flex-row column-gap-3">
                  <ul className="d-flex flex-column">
                    {flattenedTechnologies &&
                      flattenedTechnologies
                        ?.slice(0, 6)
                        .map((technology, index) => (
                          <>
                            <Link
                              to={`/technologies/${technology?.node?.slug}`}
                            >
                              <li key={index}>{technology?.node?.title}</li>
                            </Link>
                          </>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion" id="accordion">
          <div class="card f-tech">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <div
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  <div className="f-head">
                    <h5>Industries</h5>
                  </div>
                </div>
              </h5>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="link-list d-flex flex-row column-gap-3">
                  <ul className="d-flex flex-column">
                    <li>Resturants</li>
                    <li>Real Estate</li>
                    <li>E-Commerce</li>
                    <li>Education</li>
                    <li>Saas</li>
                    <li>News</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion" id="accordion">
          <div class="card f-tech">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <div
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  <div className="f-head">
                    <h5>Projects</h5>
                  </div>
                </div>
              </h5>
            </div>

            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="link-list d-flex flex-row column-gap-3">
                  <ul className="d-flex flex-column">
                    {footerProjects.slice(0, 6).map((Project, index) => (
                      <>
                        <Link to={`/work/${Project.node.slug}`}>
                          <li key={index}>{Project.node.projectName}</li>
                        </Link>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion" id="accordion">
          <div class="card f-tech">
            <div class="card-header" id="headingFive">
              <h5 class="mb-0">
                <div
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  <div className="f-head">
                    <h5>About Us</h5>
                  </div>
                </div>
              </h5>
            </div>

            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div className="link-list">
                  <ul className="d-flex flex-column">
                    <Link to="/about">
                      <li>About</li>
                    </Link>

                    <Link to="/contact">
                      <li>Contact Us</li>
                    </Link>

                    <Link to="/blog">
                      <li>Blog</li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="line right"></div>

      <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="item md-mb50">
                <div className="title">
                  <h5>Contact Us</h5>
                </div>
                <ul>
                  <li>
                    <span className="icon pe-7s-map-marker"></span>
                    <div className="cont">
                      <h6>Official Address</h6>
                      <a
                        href="https://goo.gl/maps/WKCdTxeV5aHbTuYR7"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p>
                          195-A, Lane 2, Kiran Vihar Extension, Ludhiana, Punjab
                          141022
                        </p>
                      </a>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-mail"></span>
                    <div className="cont">
                      <h6>Email Us</h6>
                      <p>
                        {" "}
                        <a href="mailto:info@evolvan.com">
                          info@evolvan.com
                        </a>{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <span className="icon pe-7s-call"></span>
                    <div className="cont">
                      <h6>Call Us</h6>
                      <p>
                        <a href="tel:+918360801493">+91 83608 01493</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item md-mb50">
                <div className="title">
                  <h5>Recent News</h5>
                </div>
                <ul>
                  {recentNewsData &&
                    recentNewsData.slice(0, 2).map((news, i) => {
                      return (
                        <li key={i} className="d-flex">
                          <div className="mr-2">
                            <Link to={`/blog/${news?.node.slug}`}>
                              <GatsbyImage className="blog-img" loading ="lazy"
                                image={news.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                alt={news?.node?.featuredImage?.node?.altText}
                              />
                            </Link>
                          </div>
                          <div className="sm-post d-flex flex-column">
                            <Link to={`/blog/${news?.node.slug}`}>
                              <p>{news?.node.title}</p>
                            </Link>
                            <span className="date mt-5">
                              {_resolveTimestamp(news?.node.date)}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <div className="logo">
                  <div className="evlvn-logo">
                    <img loading ="lazy"
                      className="mainLogo"
                      src={
                        theme === "themeL"
                          ? appData.darkLogo
                          : appData.darkLogo
                      }
                      alt="logo"
                    />
                  </div>

                  <div className="logos">
                    <img class="mainLogo" loading ="lazy"
                      src="/img/ITCA_logo_0300x300.webp"
                      alt="ITCA Logo"
                      content="noindex, nofollow"
                    ></img>

                    <img className="mainLogo" loading ="lazy"
                      src="/img/esc-logo-1-600x234.png"
                      alt="ESC Logo"
                      content="noindex, nofollow"
                    ></img>
                  </div>
                </div>
                <div id="social-icons" className="social">
                  <a
                    href="http://g.page/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-map-marker"></i>
                  </a>
                  <a
                    href="http://fb.com/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="http://twitter.com/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="http://linkedin.com/company/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://www.behance.net/evolvan9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-behance"></i>
                  </a>
                  <a
                    href="https://dribbble.com/evolvan"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@evolvan9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/evolvan9/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div className="copy-right">
                  <p>
                    &copy; {year}, Evolvan Info Solutions Pvt Ltd.
                    <Link to="https://evolvan.com" target="_blank">
                      Evolvan
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
